<template>
    <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Mapping Customer</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="code" class="form-label">Airline</label>
                <airline-combo v-model="model.airlineId" />
                <bs-input-error :errors="errorMessages" keyError="AirlineCode" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Location</label>
                <location-combo v-model="model.locationId" :textDefault="model.locationCode" />
                <bs-input-error :errors="errorMessages" keyError="Location" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Sold To</label>
                <customer-combo v-model="model.soldToId" :textDefault="model.soldToCode" />
                <bs-input-error :errors="errorMessages" keyError="SoldTo" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Ship To</label>
                <customer-combo v-model="model.shipToId" :textDefault="model.shipToCode" />
                <bs-input-error :errors="errorMessages" keyError="ShipTo" />
            </div>
            
            <div class="mb-3">
                <label for="code" class="form-label">Payer</label>
                <customer-combo v-model="model.payerId" :textDefault="model.payerCode" />
                <bs-input-error :errors="errorMessages" keyError="Payer" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Bill To</label>
                <customer-combo v-model="model.billToId" :textDefault="model.billToCode" />
                <bs-input-error :errors="errorMessages" keyError="BillTo" />
            </div>
            
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import MappingAircraftService from "@/services/mapping-aircraft-service.js";
import LocationCombo from "@/components/LocationCombo";
// import AirlineCombo from "@/components/AirlineCombo";
import CustomerCombo from "@/components/CustomerCombo";

export default {
    components: {
        LocationCombo,
        // AirlineCombo,
        CustomerCombo
    },
    data: function() {
        return {
            id: 0,
            model: MappingAircraftService.default(),
            errorMessages: null
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (id !== undefined && id !== null && id !== 0) {
            this.id = id;
            this.loadData();
        }
    },
    methods: {
        async loadData() {
            if (this.id === undefined || this.id === null || this.id === 0) 
            {
                return;
            }

            const result = await MappingAircraftService.getObject(this.id);
            if (result.status) {
                this.errorMessages = result.errorMessages;
            }
            else {
                this.errorMessages = null;
            }

            this.model = result.data;
        },
        async onPopupClosed() {
            this.$router.push('/mapping-aircraft');
        },
        async close() {
            this.$refs.form.closePopup();
        },
        async saveData() 
        {
            try {
                this.$store.commit('progressCounterQueueIncrement');

                let status = false;
                if (this.id !== undefined && this.id !== null && this.id !== 0) 
                {
                    const result = await MappingAircraftService.update(this.model, this.id);
                    status = result.status;
                    if (status) {
                        this.errorMessages = null;
                    }
                    else{
                        this.errorMessages = result.errorMessages;
                    }
                }
                else 
                {
                    const result = await MappingAircraftService.create(this.model);
                    status = result.status;
                    console.log('save status', status);
                    if (status) {
                        this.errorMessages = null;
                        this.id = result.data.id;
                    }
                    else
                    {
                        this.errorMessages = result.errorMessages;
                    }
                }

                if (!status) return;

                this.$refs.form.closePopup();
                
                const responseData = await MappingAircraftService.getList({
                    airlineIdEquals: this.model.airlineId,
                    locationIdEquals: this.model.locationId
                });
                const newModel = responseData.data.data[0];
                this.$router.push(`/mapping-aircraft/detail/${newModel.id}`);


            }
            finally 
            {
                this.$store.commit('progressCounterQueueDecrement');
            }
        }
    }
}
</script>